
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const formatTools = require( "../../../lib/formatTools");
const webappsAPI = require("../../../lib/requestWebapps");
const emailerAPI = require("../../../lib/requestEmailer");


const ENTITYID="emailer";

const EMAILERKEY="nnemailer";
const CONTENTTYPE_PADDING=1;
const CONTENTTYPE_IMAGE=2;
const CONTENTTYPE_TEMPLATE=3;
const CONTENTTYPE_MARKUP=4;

const searchFields = [
	{"label":"Target Date", "dbfield": "emailer_targetdate", "type": "date", "filtertype": "date"},
	{"label":"Name", "dbfield": "emailer_name", "type": "text", "filtertype": "text"},
	{"label":"Email Date", "dbfield": "emailer_actualdate", "type": "date", "filtertype": "date"},
	{"label":"Email Subject", "dbfield": "emailer_subject", "type": "text", "filtertype": "text"},
	{"label":"Status", "dbfield": "emailerstatus_name", "type": "text", "filtertype": "text"},
	{"label":"Last Updated", "dbfield": "emailer_updated", "type": "datetime", "filtertype": "datetime"},
];


const newformFields = [
	{"label":"Target Date", "field": "emailer_targetdate", "value": "", "input": "date", "mode": "unique"},
	{"label":"Name", "field": "emailer_name", "value": "", "input": "textbox", "mode": "unique"},
];

const formFields = [
	{"label":"Target Date", "field": "emailer_targetdate", "value": "", "input": "date", "mode": "readonly"},
	{"label":"Name", "field": "emailer_name", "value": "", "input": "textbox", "mode": "readonly"},
];

const commonformfield = [
	{"label":"Default path", "field": "emailer_path", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Email Date", "field": "emailer_actualdate", "value": "", "input": "date", "mode": "required"},
	{"label":"Email Subject", "field": "emailer_subject", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Status", "field": "emailerstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1,"display":"Draft"},
		{"value":2,"display":"Finalized"},
		{"value":3,"display":"Completed"},
		{"value":4,"display":"Cancelled"},
	]},
	{"label":"Last Updated", "field": "emailer_updated", "value": "", "input": "datetime", "mode": "readonly"}
]

const subformFields = [
	{
		"subformid": "emailerrow",
		"label": "Content",
		"table": "emailerrow",
		"sort": "emailerrow_sortid",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 200,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Sort ID",
				"field": "emailerrow_sortid",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "normal"
			},
			{
				"label": "Row #",
				"field": "emailerrow_row",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Col #",
				"field": "emailerrow_col",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Width %",
				"field": "emailerrow_size",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Type",
				"field": "emailerrow_type",
				"subtotalid": "",
				"value": "",
				"input": "combo",
				"mode": "required",
				"options": [
					{"value":CONTENTTYPE_PADDING,"display":"Padding"},
					{"value":CONTENTTYPE_IMAGE,"display":"Image"},
					{"value":CONTENTTYPE_TEMPLATE,"display":"Template"},
					{"value":CONTENTTYPE_MARKUP,"display":"Markup"},
				]
			},
			{
				"label": "Content/Details",
				"field": "emailerrow_template",
				"subtotalid": "",
				"value": "",
				"input": "textarea",
				"mode": "required"
			},
			{
				"label": "Label/Alt Text",
				"field": "emailerrow_description",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Link",
				"field": "emailerrow_linkurl",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "emailerimage",
		"label": "Images (JPG)",
		"table": "emailerimage",
		"sort": "emailerimage_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 200,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name",
				"field": "emailerimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Image",
				"field": "emailerimage_image",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "asset/email/uploads", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "limit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 1024,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 1024,	// OPTIONAL: Will be required for some imagemode
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "emailerart",
		"label": "Graphics (PNG)",
		"table": "emailerart",
		"sort": "emailerart_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 200,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name",
				"field": "emailerart_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Image",
				"field": "emailerart_image",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"png", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "asset/email/uploads", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "limit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 1024,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 1024,	// OPTIONAL: Will be required for some imagemode
			}
		],
		"footerlist": [
		]
	}
];




const EmailerPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [docid, setDocid] = useState(-1);
	const [docstatusid, setDocstatusid] = useState(0);
	const [updatedflag, setUpdatedflag] = useState(false);
	const [emailerparam, setEmailerparam] = useState({});
	const [loading, setLoading] = useState(false);
	const [preview, setPreview] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));
		customparam.emailer_updated = formatTools.getDateStr();

		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}

		if (pkid === 0) {
			customparam.emailer_path = customparam.emailer_targetdate.replace(/-/g,"")+"_"+customparam.emailer_name.toLowerCase().replace(/\s/g,"_").replace(/[^a-z0-9_]/gi, '')+"_"+customparam.emailer_updated.replace(/[^0-9]/gi, '');
		}
		//callback({"status":"Error"}); return;

		setLoading(true);
		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			setLoading(false);
			callback(response);
		});
	}

	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < subformFields.length) {
			if (subformFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}

	function markRowColValues(subform)
	{
		var rowidx = 0;
		var colidx = 0;
		var sortcolid = 0;
		var tmprownum = 0;
		var tmpcolnum = 0;
		var flagmodified = false;
		while (rowidx < subform.length) {
			flagmodified = false;
			sortcolid = 0;
			colidx = 0;
			while (colidx < subform[rowidx].length) {
				if (subform[rowidx][colidx].field === "emailerrow_sortid") {
					sortcolid = colidx;
				}
				if (subform[rowidx][colidx].field === "emailerrow_col") {
					if (subform[rowidx][colidx].modified) {
						flagmodified = true;
					}
					tmpcolnum = parseInt(subform[rowidx][colidx].value, 10);
					if (tmpcolnum < 1) {
						return {"status":"Error", "message":"Invalid column # "+subform[rowidx][colidx].value};
					}
				}
				if (subform[rowidx][colidx].field === "emailerrow_row") {
					if (subform[rowidx][colidx].modified) {
						flagmodified = true;
					}
					tmprownum = parseInt(subform[rowidx][colidx].value, 10);
					if (tmprownum < 1) {
						return {"status":"Error", "message":"Invalid row # "+subform[rowidx][colidx].value};
					}
				}
				colidx++;
			}
			if (flagmodified) {
				subform[rowidx][sortcolid].value = tmprownum*1000+tmpcolnum;
				subform[rowidx][sortcolid].modified = true;
			}

			rowidx++;
		}
		return {"status":"OK"};
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var subformidx = findSubformIdxByTablename("emailerrow");
		if (subformidx>=0) {

			var tmpres = markRowColValues(subformlist[subformidx]);
			if (tmpres.status !== "OK") {
				return tmpres;
			}
		}

		return {"status":"OK"};
	}


	function customEditFieldInfo(inputlist, idx)
	{
		if (docstatusid !== 1) {
			var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		return inputlist[idx];

	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "emailer_updated") {
			if (loading) {
				return <div className="text-centered">Please wait...</div>
			}
			if (!inputlist[idx].value) {
				return null;
			} else if (inputlist[idx].value.length < 1) {
				return null;
			}
			return <div className="text-centered">
				<div>
					<button title={"Test Email"} className={"textbutton"} onClick={sendTestEmail}>
						Send Test Email
					</button>
					{preview === "" && <>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<button title={"Preview"} className={"textbutton"} onClick={getPreview}>
							Get Preview
						</button>
					</>}
				</div>
				{preview !== "" && <div>
					<iframe frameBorder={0} style={{border:"1px solid #dddddd" ,width:"100%"}} srcdoc={preview} />
					<br/>
					<button title={"Preview"} className={"textbutton"} onClick={(e)=>{setPreview("");}}>
						x Close Preview
					</button>
				</div>}
			</div>;
		}
		// default
		return null;
	}

	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		var newstatusid = 1;
		if (newformdata.hasOwnProperty("emailer_id")) {
			if (newformdata.emailer_id) {
				setEmailerparam({
					"name": newformdata.emailer_path,
					"subject": newformdata.emailer_subject
				});
				newdocid = newformdata.emailer_id;
				if (updatedflag) {
					const emailobj = loadEmailerObj(newformdata);
					if (emailobj.hasOwnProperty("base")) {
						emailerAPI.generateHTML(EMAILERKEY, "nn/"+newformdata.emailer_path, emailobj, token).then(response => {
							//console.log(response);
						});
					}
				}
			}
		}
		if (newformdata.hasOwnProperty("emailerstatus_id")) {
			if (newformdata.emailerstatus_id) {
				newstatusid = newformdata.emailerstatus_id;
			}
		}
		setUpdatedflag(docid === newformdata.emailer_id); // Check against loading of new document
		setDocid(newdocid);
		setDocstatusid(newstatusid);
		setPreview("");
	}

	function getImageJSON(imgdata)
	{
		var tmpout = {
			"type": "rowimage",
			"imgurl": imgdata.emailerrow_template,
			"title": imgdata.emailerrow_description
		};
		if (imgdata.emailerrow_linkurl !== "") {
			tmpout.url = imgdata.emailerrow_linkurl;
		}
		return tmpout;
	}

	function getCellDataObj(celldata)
	{
		if (celldata.emailerrow_type===CONTENTTYPE_TEMPLATE) {
			return {
				"template": "nn/"+celldata.emailerrow_template
			};
		} else if (celldata.emailerrow_type===CONTENTTYPE_PADDING) {
			return {
				"type": "padding",
				"width": celldata.size+"%"
			};
		} else if (celldata.emailerrow_type===CONTENTTYPE_MARKUP) {
			return {
				"type": "markup",
				"markup": celldata.emailerrow_template
			};
		} else if (celldata.emailerrow_type===CONTENTTYPE_IMAGE) {
			return getImageJSON(celldata);
		}
		return {};
	}

	function getRowdataObj(rowdata)
	{
		if (rowdata.length === 1) {
			return getCellDataObj(rowdata[0]);
		}
		var colout = {
			"type": "contenlist",
			"bgcolor": "ffffff",
			"items": []
		}
		var colidx = 0;
		var tmpcol = {};
		while (colidx < rowdata.length) {
			tmpcol = getCellDataObj(rowdata[colidx], false);

			if (!tmpcol.hasOwnProperty("type")) {
				// Templates for single column only
				colidx++;
				continue;
			}
			tmpcol.width = rowdata[colidx].size+"%";
			colout.items.push(tmpcol);
			colidx++;
		}
		return colout;
	}

	function loadEmailerObj(formdata)
	{
		var subformidx = findSubformIdxByTablename("emailerrow");
		if (subformidx < 0) {
			return {};
		}

		var idx = 0;
		var output = {
			"projecturl": "https://cdn.ngnww.ph/asset/email/"+formdata.emailer_path,
			"base": "800light",
			"header": "",
			"footer": "",
			"replacements": [],
			"body": []
		};
		var emailrow = [];
		var emailrownum = -1;

		if (formdata.emailer_params) {
			if (formdata.emailer_params.length > 0) {
				output.replacements = JSON.parse(formdata.emailer_params);
			}
		}
		// group columns into emailrow
		idx = 0
		while (idx < formdata.subform[subformidx].length) {

			if (emailrownum !== formdata.subform[subformidx][idx].emailerrow_row) {
				if (emailrow.length > 0) {
					output.body.push(getRowdataObj(emailrow));
				}
				emailrow = [];
				emailrownum = formdata.subform[subformidx][idx].emailerrow_row;
			}
			emailrow.push(formdata.subform[subformidx][idx]);
			idx++;
		}
		if (emailrow.length > 0) {
			output.body.push(getRowdataObj(emailrow));
		}

		return output;
	}

	function getPreview(e)
	{
		if (e) {
			e.preventDefault();
		}
		if (emailerparam.hasOwnProperty("name")) {
			if (emailerparam.name) {
				if (emailerparam.name.length > 0) {
					setLoading(true);
					emailerAPI.getFile("output", EMAILERKEY, "nn/"+emailerparam.name, token).then(response => {
						if (response.status === "OK") {
							setPreview(response.data);
						} else {
							setPreview("<tr><td align='center'>Error</td></tr>");
						}
						setLoading(false);
					});
				}
			}
		}
	}

	function sendTestEmail(e)
	{
		if (e) {
			e.preventDefault();
		}
		const emaildestlist = [
			{	"name": "Juan Dela Cruz",
				"email": "ty@iamnet.com.ph"
			},
		];
		if (emailerparam.hasOwnProperty("name")) {
			if (emailerparam.name) {
				if (emailerparam.name.length > 0) {
					setLoading(true);
					emailerAPI.sendEmail(EMAILERKEY,[
						{"name":emailerparam.name, "subject": emailerparam.subject}
					],[],emaildestlist, token).then(response => {
						// console.log(response);
						setLoading(false);
					});
				}
			}
		}

	}


	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["messaging_name"]}
				searchFields={searchFields}
				newformFields={[newformFields.concat(commonformfield)]}
				editFields={[formFields.concat(commonformfield)]}
				editSubFormFields={subformFields}

				customEditFieldInfo={customEditFieldInfo}
				customFieldFragment={customFieldFragment}
				customSetFormData={customSetFormData}
				customSubmit={customSubmit}
				customValidateValues={formValidateValues}

				token={token} />
		</Layout>

}


export default EmailerPage;
